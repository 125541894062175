import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {getPostsByCategory} from "../services/PostService";

import './PaginaAnno.css'
import Loader from "../components/Loader";


const PaginaAnno = () => {

    const [loading,setLoading] = useState(false);
    const [error, setError] = useState("");
    /* const [lezioni, setLezioni] = useState([]) */
    const [orderedLezioni, setOrderedLezioni] = useState({})

    const navigate= useNavigate();
    

    const anniDict= {
        "1": "Prima",
        "2": "Seconda",
        "3": "Terza",
        "4": "Quarta",
        "5": "Quinta",
    }

    const categories ={
      'matematica':{
        "1": ['equazioni_lineari'],
        "2": ['sistemi_lineari', "matrici"],
        "3": [],
        "4": ['matrici'],
        "5": [],
      },
      'fisica': {
        "1": [],
        "2": [],
        "3": [],
        "4": [],
        "5": [],
      }
    }

    const categoriesDict = {
      'sistemi_lineari': 'Sistemi lineari',
      'matrici': 'Matrici',
      'equazioni_lineari': 'Equazioni lineari',
    }

    const {materia, anno} = useParams();

    let currentCategories = categories[materia][anno];

    const fetchLezioni = async() => {
      
      setLoading(true);

      let category = `${anniDict[anno]} ${materia}`

      let queryParams = {
        'fields': 'ID, title, post_thumbnail, tags, categories'
      }

      try{
        const response = await getPostsByCategory(category, queryParams);
        orderLezioni(response.data.posts);
      }catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
      
    }

    const orderLezioni = (lezioni) => {
      let ordered = {};
      currentCategories.forEach(cat => {
        let filtered = lezioni?.filter((item) => cat in item.categories);
        ordered[cat] = filtered;
      });
      setOrderedLezioni(ordered);
    }

    /* const url = `https://public-api.wordpress.com/rest/v1.1/sites/${process.env.REACT_APP_SITE_ID}/posts/?category=${anniDict[anno]} ${materia}` */

    useEffect(() => {

      fetchLezioni();
      orderLezioni();

      /*   const fetchLezione = async () => {
          try {
            const response = await axios.get(url);
            setLezioni(response.data.posts);
          } catch (err) {
            setError(err);
          } finally {
            setLoading(false);
          }
        };
    
        fetchLezione(); */
      }, []);
    

    return (
        <div className="content">
          {loading && <Loader/>}
            <div className="post-list">
            {!loading && orderedLezioni &&
                currentCategories.map((cat, i) => 
                    <div className='flex-column align-items-center subject-list' key={i}>
                      <p className="subject-name">{categoriesDict[cat]}</p>
                      {orderedLezioni[cat]?.map((item, i) => 
                        <div key={item.title} className="post align-items-center" onClick={()=>{navigate(`/lezione/${Number(item.ID)}`)}}>
                        <img src={item?.post_thumbnail?.URL} />
                        <p className="post-title">{item.title}</p>
                        </div>
                      )}
                    </div>
                    
                )
            }
          
           {/*  {lezioni &&
                lezioni.map((item,i) => 
                    <div key={i} className="post" onClick={()=>{navigate(`/lezione/${Number(item.ID)}`)}}>
                    <img src={item?.post_thumbnail?.URL} />
                    <p className="post-title">{item.title}</p>
                    </div>
                    
                )
            } */}
            </div>            
      </div>
    );
}

export default PaginaAnno; 