import axios from 'axios';

    export const getPostByIDAndFields = async (id, queryParams) => {
        if(!id){
            return new Error('Missing post ID')
        }
        let postUrl = `https://public-api.wordpress.com/rest/v1.1/sites/${process.env.REACT_APP_SITE_ID}/posts/${id}?`

        if(queryParams){
            let queryString = ''
        
            for (const [key, value] of Object.entries(queryParams)) {
                queryString = queryString.concat(`${key}=${value}`)
            }
            
            postUrl = postUrl.concat(queryString)
        }
        

        try {
            const response = await axios.get(postUrl);
            return response;
          } catch (err) {
            return new Error(err);
          }
        };


    export const getPostsByCategory = async (category, queryParams) => {
         let postsUrl = `https://public-api.wordpress.com/rest/v1.1/sites/${process.env.REACT_APP_SITE_ID}/posts/?category=${category}`

        if(queryParams){
            let queryString = ''
        
            for (const [key, value] of Object.entries(queryParams)) {
                queryString = queryString.concat(`&${key}=${value}`)
            }
            
            postsUrl = postsUrl.concat(queryString)
        }
        

        try {
            const response = await axios.get(postsUrl);
            return response;
          } catch (err) {
            return new Error(err);
          }


    }

    export const getRecentPosts = async () => {
        const POSTS_LIMIT = 9;
        let postsUrl = `https://public-api.wordpress.com/rest/v1.1/sites/${process.env.REACT_APP_SITE_ID}/posts/?limit=${POSTS_LIMIT}&fields=ID,date,title,post_thumbnail`        

        try {
            const response = await axios.get(postsUrl);
            return response;
          } catch (err) {
            return new Error(err);
          }

    }



