import React from "react";
import './Questionari.css'

const Questionari = () => {
    return (
        <div className="content">
            <div className="flex-row questionari-list">
                <div className="flex-column questionario-wrapper align-items-center">
                    <div>Autovalutazione pre-verifica</div>
                    <img src="https://fisichub.wordpress.com/wp-content/uploads/2024/11/autovalutazione_preverifica-1.png"/>
                    <a href="https://forms.gle/XMuvx94cMaE6rYWp8" target="blank">O clicca qui</a>
                </div>
                <div>
                <div className="flex-column questionario-wrapper align-items-center">
                    <div>Autovalutazione post-verifica</div>
                    <img src="https://fisichub.wordpress.com/wp-content/uploads/2024/11/autovalutazione_postverifica-1.png"/>
                    <a href="https://forms.gle/KLFSkVjuZ8W1iAav6" target="blank">O clicca qui</a>
                </div>
                </div>
            </div>
        </div>
    );
}

export default Questionari;