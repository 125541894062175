import React from "react";
import './Loader.css'

const Loader = () => {
    return (
        <div className="loader">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        width="50"
        height="50"
      >
        <circle
          cx="50"
          cy="50"
          r="40"
          strokeWidth="8"
          stroke="var(--secondary)" /* Verde del tema */
          fill="none"
          strokeDasharray="250"
          strokeDashoffset="0"
          className="loader-circle"
        />
      </svg>
    </div>
  );
}

export default Loader;