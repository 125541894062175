import React from "react";
import './Simulazioni.css'
import { useNavigate } from "react-router-dom";
const Simulazioni = () => {

    const navigate = useNavigate();

    return (
        <div className="content">
            <h2>Simulazioni</h2>
            <div className="division-bar"></div>
            <div className="simulazioni-wrapper">
                <div className="simulazioni-container">
                    <div className="simulazione-thumbnail" onClick={()=>{navigate('/simulazioni/moto-parabolico')}}>
                        <img src="https://fisichub.wordpress.com/wp-content/uploads/2024/10/parabolico.png"/>
                        <div>
                            Moto parabolico
                        </div>
                    </div>
                    <div className="simulazione-thumbnail"  onClick={()=>{navigate('/simulazioni/moto-circolare')}}>
                        <img src="https://fisichub.wordpress.com/wp-content/uploads/2024/10/circolare.png"/>
                        <div>
                            Moto circolare
                        </div>
                    </div>
                   {/*  <div className="simulazione-thumbnail"  onClick={()=>{navigate('/simulazioni/cavendish')}}>
                        <img src="https://placehold.co/400"/>
                        <div>
                            Cavendish experiment
                        </div>
                    </div> */}
                    <div className="simulazione-thumbnail" onClick={()=>{navigate('/simulazioni/unit-circle')}}>
                        <img src="https://fisichub.wordpress.com/wp-content/uploads/2024/11/unit-circle.png"/>
                        <div>
                            Circonferenza unitaria
                        </div>
                    </div>  
                    <div className="simulazione-thumbnail" onClick={()=>{navigate('/simulazioni/fourier')}}>
                        <img src="https://fisichub.wordpress.com/wp-content/uploads/2024/11/fourier.png"/>
                        <div>
                            Trasformata di Fourier
                        </div>
                    </div>  
                    <div className="simulazione-thumbnail" onClick={()=>{navigate('/simulazioni/harmonic-wave')}}>
                        <img src="https://fisichub.wordpress.com/wp-content/uploads/2024/11/harmonic.png"/>
                        <div>
                            Onda armonica
                        </div>
                    </div>  
                    <div className="simulazione-thumbnail" onClick={()=>{navigate('/simulazioni/double-pendulum')}}>
                        <img src="https://fisichub.wordpress.com/wp-content/uploads/2024/12/double_pendulum.png"/>
                        <div>
                            Pendolo doppio
                        </div>
                    </div>  
                </div>
            </div>

            <div className="division-bar"></div>
        </div>
    );
}

export default Simulazioni;