import React, { useState, useRef, useEffect } from 'react';

const MotoParabolico = () => {

  const [angle, setAngle] = useState(70);  // in gradi
  const [velocity, setVelocity] = useState(30);  // in m/s
  const [canvasWidth, setCanvasWidth] = useState(0);
  const canvasRef = useRef(null);
  const maxVelocity = window.innerWidth > 700 ? 35 :30;  // Limite massimo della velocità

  useEffect(()=>{
    let width = window.innerWidth > 700 ? 600 : window.innerWidth * 0.95;
    setCanvasWidth(width);
  },[])

  useEffect(() => {
    const canvas = canvasRef.current;
    
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    drawAxes(ctx);  // Disegna assi con i label
    drawTrajectory(ctx);
  }, [angle, velocity, canvasWidth]);

  const drawAxes = (ctx) => {

    ctx.strokeStyle = '#9BA5A3';
    ctx.fillStyle = '#9BA5A3';

    const originOffset = window.innerWidth > 700 ? 50 : 20;
    
    const canvasHeight = 400;
    const scale = 4.5;  // scala per distanze

    const arrowSize = 10; // Dimensione della freccia
  
    // Disegna l'asse x
    ctx.beginPath();
    ctx.moveTo(originOffset, canvasHeight - originOffset);  // Asse x parte in basso a sinistra
    ctx.lineTo(canvasWidth -5 , canvasHeight - originOffset);  // Lato destro
    ctx.stroke();

     // Freccia asse x
    ctx.beginPath();
    ctx.moveTo(canvasWidth - 5, canvasHeight - originOffset); // Punta della freccia
    ctx.lineTo(canvasWidth - 5 - arrowSize, canvasHeight - originOffset - arrowSize / 2);
    ctx.moveTo(canvasWidth - 5, canvasHeight - originOffset); // Ritorna alla punta
    ctx.lineTo(canvasWidth - 5 - arrowSize, canvasHeight - originOffset + arrowSize / 2);
    ctx.stroke();




    // Disegna l'asse y
    ctx.beginPath();
    ctx.moveTo(originOffset, canvasHeight - originOffset);  // Asse y parte dall'origine
    ctx.lineTo(originOffset, originOffset);  // Su verso l'alto
    ctx.stroke();

      // Freccia asse y
    ctx.beginPath();
    ctx.moveTo(originOffset, originOffset); // Punta della freccia
    ctx.lineTo(originOffset - arrowSize / 2, originOffset + arrowSize);
    ctx.moveTo(originOffset, originOffset); // Ritorna alla punta
    ctx.lineTo(originOffset + arrowSize / 2, originOffset + arrowSize);
    ctx.stroke();

    // Etichette e tacche sugli assi
    ctx.font = '12px Arial';
    ctx.fillText('0', originOffset-10, canvasHeight - (originOffset-10));  // Origine
    ctx.fillText('x (m)', canvasWidth - (originOffset-10), canvasHeight - (originOffset-20));  // Label x
    ctx.fillText('y (m)', 10, 60);  // Label y


    ctx.setLineDash([3, 3]); // Linee tratteggiate

  for (let i = 10; i <= canvasWidth/(scale+1); i += 10) {
    const xPos = originOffset + i * scale;
    ctx.beginPath();
    ctx.moveTo(xPos, canvasHeight - originOffset);
    ctx.lineTo(xPos, originOffset);
    ctx.stroke();
    ctx.fillText(i.toString(), xPos, canvasHeight - (originOffset-20));
  }

  for (let i = 10; i <= 65; i += 10) {
    const yPos = canvasHeight - originOffset - i * scale;
    ctx.beginPath();
    ctx.moveTo(originOffset, yPos);
    ctx.lineTo(canvasWidth - originOffset, yPos);
    ctx.stroke();
    ctx.fillText(i.toString(), originOffset -20, yPos + 5);
  }

  ctx.setLineDash([]); // Reset linee
  };

  const drawTrajectory = (ctx) => {

    
    ctx.strokeStyle = '#9BA5A3';
    ctx.lineWidth = 2; 

    let originOffset = window.innerWidth > 700 ? 50 : 20;
    const g = 9.81;  // gravità
    const radians = (Math.PI / 180) * angle;
    const scale = 4.5;  // Scala in pixel per le unità
    const timeMax = (2 * velocity * Math.sin(radians)) / g;  // Tempo massimo del volo
    ctx.beginPath();
    
    ctx.moveTo(originOffset, 400 -originOffset );  // Partenza dall'origine (traslato di 50 per i margini)
    for (let t = 0; t <= timeMax; t += 0.04) {
      const x = velocity * t * Math.cos(radians);
      const y = velocity * t * Math.sin(radians) - 0.5 * g * t * t;

      ctx.lineTo(originOffset + x * scale, 400- originOffset - y * scale);  // Scala e traslazione
    }
    ctx.stroke();
  };

   // Calcoli per gittata e altezza massima
   const g = 9.81;
   const radians = (Math.PI / 180) * angle;
   const range = (Math.pow(velocity, 2) * Math.sin(2 * radians)) / g;
   const maxHeight = (Math.pow(velocity * Math.sin(radians), 2)) / (2 * g);

  return (
    <div>
      <canvas ref={canvasRef} width={canvasWidth} height={400} style={{ border: '1px solid black' }}></canvas>
      {/* Valori di gittata e altezza massima */}
      <div style={{ marginTop: '10px', color: '#9BA5A3' }}>
        <p>Gittata: {range.toFixed(2)} m</p>
        <p>Altezza massima: {maxHeight.toFixed(2)} m</p>
      </div>
      <div>
        <label>Angolo: {angle}°</label>
        <input
          type="range"
          min="0"
          max="90"
          value={angle}
          onChange={(e) => setAngle(e.target.value)}
          style={{ background: '#9BA5A3', color: '#FFFFFF' }} // Colore dello slider
        />
      </div>
      <div>
        <label>Velocità: {velocity} m/s</label>
        <input
          type="range"
          min="20"
          max={maxVelocity}
          value={velocity}
          onChange={(e) => setVelocity(e.target.value)}
          style={{ background: '#9BA5A3', color: '#FFFFFF' }} // Colore dello slider
        />
      </div>
    </div>
  );
};

export default MotoParabolico;