import React from "react";


const Fourier = () =>{
    return (
        <div>
            <iframe src="https://www.desmos.com/calculator/k1ytso0utg" width="90%" height="500"  frameborder='0'></iframe>
        </div>
    )
}

export default Fourier;