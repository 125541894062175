import { faStop } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useEffect, useState } from "react";

const DoublePendulum = () => {
  const canvasRef = useRef(null);

 /*  const [running, setRunning] = useState(true); */

  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight*0.75;

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    const windowDimension = windowWidth < windowHeight ? windowWidth : windowHeight;

    

    let r1 = windowDimension/4-10; // Lunghezza del primo braccio
    let r2 = windowDimension/4-10; // Lunghezza del secondo braccio
    let m1 = 12; // Massa del primo pendolo
    let m2 = 10; // Massa del secondo pendolo
    let a1 = Math.random()*Math.PI ; // Angolo iniziale del primo pendolo
    let a2 = Math.random()*Math.PI / 2; // Angolo iniziale del secondo pendolo
    let a1_v = 0; // Velocità angolare del primo pendolo
    let a2_v = 0; // Velocità angolare del secondo pendolo
    const g = 1; // Accelerazione gravitazionale

    let path = []; // Traccia della simulazione

    let lastTime = 0; // Memorizza l'ultimo timestamp
    const fps = 35;  // Frequenza desiderata in frame al secondo
    const interval = 1000/fps; // Intervallo di aggiornamento in millisecondi

    // Disegna la simulazione
    const draw = (timestamp) => {

      if (timestamp - lastTime >= interval) {
        lastTime = timestamp;
        // Cancella il canvas
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // Sfondo
        ctx.fillStyle = "#2f4f4f"; // Sfondo scuro
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        ctx.save(); // Salva il contesto
        ctx.translate(canvas.width / 2, canvas.height / 2);

        // Calcolo delle equazioni del moto
        let num1 = -g * (2 * m1 + m2) * Math.sin(a1);
        let num2 = -m2 * g * Math.sin(a1 - 2 * a2);
        let num3 = -2 * Math.sin(a1 - a2) * m2;
        let num4 = a2_v * a2_v * r2 + a1_v * a1_v * r1 * Math.cos(a1 - a2);
        let den = r1 * (2 * m1 + m2 - m2 * Math.cos(2 * a1 - 2 * a2));
        let a1_a = (num1 + num2 + num3 * num4) / den;

        num1 = 2 * Math.sin(a1 - a2);
        num2 = a1_v * a1_v * r1 * (m1 + m2);
        num3 = g * (m1 + m2) * Math.cos(a1);
        num4 = a2_v * a2_v * r2 * m2 * Math.cos(a1 - a2);
        den = r2 * (2 * m1 + m2 - m2 * Math.cos(2 * a1 - 2 * a2));
        let a2_a = (num1 * (num2 + num3 + num4)) / den;

        // Aggiorna velocità e angoli
        a1_v += a1_a;
        a2_v += a2_a;
        a1 += a1_v;
        a2 += a2_v;

        // Smorzamento
        a1_v *= 1;
        a2_v *= 1;

        // Coordinate del primo pendolo
        let x1 = r1 * Math.sin(a1);
        let y1 = r1 * Math.cos(a1);

        // Coordinate del secondo pendolo
        let x2 = x1 + r2 * Math.sin(a2);
        let y2 = y1 + r2 * Math.cos(a2);

        // Disegna i pendoli
        ctx.strokeStyle = "#000000"; // Nero per le linee
        ctx.lineWidth = 2;

        // Braccio del primo pendolo
        ctx.beginPath();
        ctx.moveTo(0, 0);
        ctx.lineTo(x1, y1);
        ctx.stroke();

        // Massa del primo pendolo
        ctx.fillStyle = "#00c194"; // Verde tema
        ctx.beginPath();
        ctx.arc(x1, y1, m1, 0, Math.PI * 2);
        ctx.fill();

        // Braccio del secondo pendolo
        ctx.beginPath();
        ctx.moveTo(x1, y1);
        ctx.lineTo(x2, y2);
        ctx.stroke();

        // Massa del secondo pendolo
        ctx.fillStyle = "#fff7d6"; // Giallo chiaro tema
        ctx.beginPath();
        ctx.arc(x2, y2, m2, 0, Math.PI * 2);
        ctx.fill();

        // Disegna la traccia
        path.push([x2, y2]);
        ctx.strokeStyle = "#e2ffff"; // Azzurro tenue
        ctx.beginPath();
        for (let i = 0; i < path.length - 1; i++) {
          ctx.moveTo(path[i][0], path[i][1]);
          ctx.lineTo(path[i + 1][0], path[i + 1][1]);
        }
        ctx.stroke();

        // Limita la lunghezza della traccia
        if (path.length > 400) {
          path.shift();
        }

        ctx.restore(); // Ripristina il contesto
      
      }
      requestAnimationFrame(draw);
    };

    draw(); // Avvia il disegno

    // Pulizia dell'animazione quando il componente viene smontato
    return () => cancelAnimationFrame(draw);
  }, []);

  return (
    <div className='content'>
        <canvas
            ref={canvasRef}
            width={windowWidth}
            height={windowHeight}
            style={{
                display: "block",
                margin: "0 auto",
                backgroundColor: "var(--background)",
            }}
        ></canvas>
     {/*    <div className="animation-buttons flex-row">
          <FontAwesomeIcon icon={faStop} onClick={()=>{setRunning(!running); console.log("changed" + running)}}/>
        </div> */}
    </div>
  );
};

export default DoublePendulum;