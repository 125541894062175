import React, {useState, useEffect, useRef} from "react";
const MotoCircolare = () => {
    const canvasRef = useRef(null);
    const [time, setTime] = useState(0);
    const radius = 100;  // Raggio della circonferenza
    const xorigin = 2*radius;
    const yaxisheight = 150;
    const speed = 0.03; // Velocità angolare
  
    useEffect(() => {
      const interval = setInterval(() => {
        setTime((prevTime) => prevTime + speed);  // Incrementa il tempo
      }, 50);
      return () => clearInterval(interval);
    }, []);
  
    useEffect(() => {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
  
      // Pulisci il canvas
      ctx.clearRect(0, 0, canvas.width, canvas.height);
  
      drawCircle(ctx);    // Disegna la circonferenza
      drawPointOnCircle(ctx);  // Disegna il punto in movimento
      drawSineGraph(ctx);  // Disegna il grafico spazio-tempo
    }, [time]);
  
    const drawCircle = (ctx) => {
      // Disegna la circonferenza
      ctx.beginPath();
      ctx.arc(radius, yaxisheight, radius, 0, 2 * Math.PI);
      ctx.stroke();
    };
  
    const drawPointOnCircle = (ctx) => {
      // Coordinate del punto sulla circonferenza
      const x =  radius * (1-Math.cos(time));
      const y = yaxisheight - radius * Math.sin(time);
  
      // Disegna il punto
      ctx.beginPath();
      ctx.arc(x, y, 5, 0, 2 * Math.PI);
      ctx.fillStyle = 'red';
      ctx.fill();
    };
  
    const drawSineGraph = (ctx) => {
      // Disegna il grafico spazio-tempo (sinusoidale)
      const graphWidth = 500;
      const graphHeight = 2*yaxisheight;
      const scaleX = 10;  // Scala temporale per il grafico
      const scaleY = 50;  // Scala verticale per l'ampiezza del seno
  
      // Disegna assi
      ctx.beginPath();
      ctx.moveTo(xorigin, graphHeight / 2);
      ctx.lineTo(graphWidth + 50, graphHeight / 2);  // Asse x
      ctx.moveTo(xorigin, 0);
      ctx.lineTo(xorigin, graphHeight);  // Asse y
      ctx.stroke();
  
      // Disegna il grafico del seno basato sul movimento del punto
      ctx.beginPath();
      ctx.moveTo(xorigin, graphHeight / 2);  // Punto iniziale del grafico
  
      for (let t = 0; t <= time *scaleX; t += 0.2) {
        const sineValue =  radius * Math.sin(t*speed*scaleX/(Math.PI-0.15));//radius * Math.sin(t *speed); // Posizione verticale del punto
        const y = graphHeight / 2 - sineValue;  // Calcola l'altezza da disegnare
        ctx.lineTo(xorigin + t, y);
      }
     /*    const sineValue =  radius * Math.sin(time);//radius * Math.sin(t *speed); // Posizione verticale del punto
        const y = graphHeight / 2 - sineValue;  // Calcola l'altezza da disegnare
        ctx.lineTo(xorigin + time, y); */
      ctx.strokeStyle = 'blue';
      ctx.stroke();
  
      // Aggiungi un punto che rappresenta l'altezza attuale del punto sulla circonferenza
      const currentY = radius * Math.sin(time); // Altezza attuale del punto
      ctx.beginPath();
      //uncomment this to make the shadow point follow the sine graph
      //ctx.arc(xorigin + time * scaleX, graphHeight / 2 - currentY, 4, 0, 2 * Math.PI);
      ctx.arc(xorigin, graphHeight / 2 - currentY, 5, 0, 2 * Math.PI);
      ctx.fillStyle = 'green';
      ctx.fill();
    };
  
    return (
      <canvas ref={canvasRef} width={600} height={300} style={{ border: '1px solid black' }}></canvas>
    );
  };

  export default MotoCircolare;