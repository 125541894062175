import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import './Didattica.css';
import { getRecentPosts } from '../services/PostService';
import Loader from '../components/Loader';

const Didattica = () => {
   // Gestione dello stato del dropdown per Matematica e Fisica
   const [showMathDropdown, setShowMathDropdown] = useState(false);
   const [showPhysicsDropdown, setShowPhysicsDropdown] = useState(false);
   const [recentPosts, setRecentPosts] = useState([]);

   const [loading, setLoading] = useState(true);
   const [error, setError] = useState('')

   const navigate = useNavigate();

   useEffect(()=>{
      
    fetchRecentPosts();
     
   }, [])

   const fetchRecentPosts = async () => {
      setLoading(true);
      try{
        const response = await getRecentPosts();
        setRecentPosts(response.data.posts);
      }catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
   }

   const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('it-IT', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });
  }
 
   return (
     <div className="content didattica-page">
       <h2>Didattica</h2>
        <div className='subject-choice'>
          <div>Scegli la materia</div>
          <div className='subject-buttons-container'>
            {/* Bottone Matematica */}
            <div className="dropdown">
              <button className='button-primary button-subjet' onClick={() => {setShowMathDropdown(!showMathDropdown); setShowPhysicsDropdown(false)}}>
                Matematica
              </button>
              {showMathDropdown && (
                <ul className="dropdown-menu">
                  <li onClick={()=>{navigate('/lezioni/matematica/1')}}>Anno 1</li>
                  <li onClick={()=>{navigate('/lezioni/matematica/2')}}>Anno 2</li>
                  <li onClick={()=>{navigate('/lezioni/matematica/3')}}>Anno 3</li>
                  <li onClick={()=>{navigate('/lezioni/matematica/4')}}>Anno 4</li>
                  <li onClick={()=>{navigate('/lezioni/matematica/5')}}>Anno 5</li>
                </ul>
              )}
            </div>
  
            {/* Bottone Fisica */}
            <div className="dropdown">
              <button className='button-primary button-subjet' onClick={() => {setShowMathDropdown(false);setShowPhysicsDropdown(!showPhysicsDropdown)}}>
                Fisica
              </button>
              {showPhysicsDropdown && (
                <ul className="dropdown-menu">
                  <li onClick={()=>{navigate('/lezioni/fisica/1')}}>Anno 1</li>
                  <li onClick={()=>{navigate('/lezioni/fisica/2')}}>Anno 2</li>
                  <li onClick={()=>{navigate('/lezioni/fisica/3')}}>Anno 3</li>
                  <li onClick={()=>{navigate('/lezioni/fisica/4')}}>Anno 4</li>
                  <li onClick={()=>{navigate('/lezioni/fisica/5')}}>Anno 5</li>
                </ul>
              )}
            </div>
          </div>
        </div>
       {/*  <div className='subject-choice'>
            <div>oppure cerca una simulazione</div>
            <div className='subject-buttons-container'>
            <div className='dropdown'>
            <button className='button-primary button-subjet' onClick={() => {navigate('/simulazioni')}}>
                Simulazioni
              </button>
            </div>
            </div>
          
        </div> */}
        <div className='recent-articles flex-column align-items-center'>
          {loading && <Loader />}
          <div className='recent-article-title'>
            ARTICOLI RECENTI
          </div>         
          <div className='recent-articles-wrapper'>
            {recentPosts &&
                  recentPosts.map((item,i) => 
                      <div key={i} className="recent-post" onClick={()=>{navigate(`/lezione/${Number(item.ID)}`)}}>
                      <img src={item?.post_thumbnail?.URL} />
                      <p className="recent-post-title">{item.title}</p>
                      <p>{formatDate(item.date)}</p>
                      </div>
                      
                  )
              }
          </div>
        </div>
      </div>
   );
};

export default Didattica;