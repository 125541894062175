import React, { useState, useRef, useEffect } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { OrbitControls, Sphere, Cylinder } from '@react-three/drei';

// Costante di Gravitazione Universale semplificata
const G = 6.67430e-11;

// Massa singola per la simulazione
function Mass({ position, setPosition, color }) {
  const meshRef = useRef();

  useFrame(() => {
    if (meshRef.current) {
      meshRef.current.position.set(...position);
    }
  });

  const handleDrag = (e) => {
    setPosition([e.point.x, e.point.y, e.point.z]);
  };

  return (
    <Sphere ref={meshRef} args={[0.1, 32, 32]} position={position}>
      <meshStandardMaterial color={color} />
      <mesh onPointerMove={handleDrag} />
    </Sphere>
  );
}

// Componente principale dell'Esperimento di Cavendish
function Cavendish() {
  const [movableMass1Pos, setMovableMass1Pos] = useState([1, 0, 0]);
  const [movableMass2Pos, setMovableMass2Pos] = useState([-1, 0, 0]);
  const [fixedMass1Pos] = useState([1.5, 0, 0.5]);
  const [fixedMass2Pos] = useState([-1.5, 0, -0.5]);

  // Forza di torsione (approssimata per esempio)
  const torsionConstant = 1e-7;

  // Calcola la distanza e la forza gravitazionale tra ogni coppia di masse
  const calculateForce = (mass1Pos, mass2Pos) => {
    const distance = Math.sqrt(
      (mass2Pos[0] - mass1Pos[0]) ** 2 +
      (mass2Pos[1] - mass1Pos[1]) ** 2 +
      (mass2Pos[2] - mass1Pos[2]) ** 2
    );
    return G / (distance ** 2);
  };

  // Calcola la forza tra le masse mobili e le masse fisse
  const force1 = calculateForce(movableMass1Pos, fixedMass1Pos);
  const force2 = calculateForce(movableMass2Pos, fixedMass2Pos);

  // Calcola l'angolo di torsione in base alla forza totale
  const torque = (force1 - force2) * 1e5; // Fattore per visualizzazione
  const angle = torque / torsionConstant;

  // Simulazione di ritorno verso l'equilibrio
  useEffect(() => {
    const interval = setInterval(() => {
      setMovableMass1Pos((pos) => [pos[0] - angle * 0.001, pos[1], pos[2]]);
      setMovableMass2Pos((pos) => [pos[0] + angle * 0.001, pos[1], pos[2]]);
    }, 50);
    return () => clearInterval(interval);
  }, [angle]);

  return (
    <div>
      <Canvas style={{ height: 400 }}>
        <ambientLight intensity={0.5} />
        <pointLight position={[10, 10, 10]} />
        <OrbitControls />

        {/* Asta della bilancia a torsione */}
        <Cylinder args={[0.02, 0.02, 2, 32]} position={[0, 0, 0]} rotation={[0, 0, Math.PI / 2]}>
          <meshStandardMaterial color="gray" />
        </Cylinder>

        {/* Masse mobili */}
        <Mass position={movableMass1Pos} setPosition={setMovableMass1Pos} color="blue" />
        <Mass position={movableMass2Pos} setPosition={setMovableMass2Pos} color="blue" />

        {/* Masse fisse */}
        <Mass position={fixedMass1Pos} setPosition={() => {}} color="red" />
        <Mass position={fixedMass2Pos} setPosition={() => {}} color="red" />
      </Canvas>

      {/* Informazioni */}
      <div>
        <p>Forza tra le masse mobili e fisse: {force1.toExponential(2)} N e {force2.toExponential(2)} N</p>
        <p>Angolo di Torsione: {angle.toFixed(2)}°</p>
      </div>
    </div>
  );
}

export default Cavendish;