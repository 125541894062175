import React, { useState } from 'react';

const angles = [
  { radians: 0, label: "0" },
  { radians: Math.PI / 6, label: "π/6" },
  { radians: Math.PI / 4, label: "π/4" },
  { radians: Math.PI / 3, label: "π/3" },
  { radians: Math.PI / 2, label: "π/2" },
  { radians: (2 * Math.PI) / 3, label: "2π/3" },
  { radians: (3 * Math.PI) / 4, label: "3π/4" },
  { radians: (5 * Math.PI) / 6, label: "5π/6" },
  { radians: Math.PI, label: "π" },
  { radians: (7 * Math.PI) / 6, label: "7π/6" },
  { radians: (5 * Math.PI) / 4, label: "5π/4" },
  { radians: (4 * Math.PI) / 3, label: "4π/3" },
  { radians: (3 * Math.PI) / 2, label: "3π/2" },
  { radians: (5 * Math.PI) / 3, label: "5π/3" },
  { radians: (7 * Math.PI) / 4, label: "7π/4" },
  { radians: (11 * Math.PI) / 6, label: "11π/6" },
];

const width = window.innerWidth;
const height = window.innerHeight;

const formatTrigValue = (value) => {
  const sqrt3 = Math.sqrt(3) / 2;
  const half = 1/2;
  const sqrt2 = 1/Math.sqrt(2)

  if (Math.abs(value - sqrt3) < 0.01) return "√3/2";
  if (Math.abs(value + sqrt3) < 0.01) return "-√3/2";
  if (Math.abs(value - half) < 0.01) return "1/2";
  if (Math.abs(value + half) < 0.01) return "-1/2";
  if (Math.abs(value) < 0.01) return "0";
  if (Math.abs(value - 1) < 0.01) return "1";
  if (Math.abs(value + 1) < 0.01) return "-1";
  if (Math.abs(value - sqrt2) < 0.01) return "1/√2";
  if (Math.abs(value + sqrt2) < 0.01) return "-1/√2"; 
  return value.toFixed(2); // Default to decimal if no special form
};

const UnitCircle = () => {
  const [selectedAngle, setSelectedAngle] = useState(null);
  const radius = 130;

  const handleClick = (angle) => {
    setSelectedAngle(angle);
  };

  return (
    <svg width={width > 700 ? width/2 : width} height={width > 700 ? height*8/10 : height*7/10} viewBox="-150 -150 300 300">
      {/* Asse X e Asse Y */}
      <line x1="-150" y1="0" x2="150" y2="0" stroke="black" />
      <line x1="0" y1="-150" x2="0" y2="150" stroke="black" />

      {/* Circonferenza Unitaria */}
      <circle cx="0" cy="0" r={radius} fill="none" stroke="black" />

      {/* Tacche e Etichette degli Angoli */}
      {angles.map((angle, index) => {
        const x = radius * Math.cos(angle.radians);
        const y = -radius * Math.sin(angle.radians);
    
        return (
          <g
            key={index}
            onClick={() => handleClick(angle)}
            style={{ cursor: "pointer"}}
          >
            <rect 
              x={x < 0 ? x*1.2 : x*0.9}
              y={y > 0 ? y*0.9 : y*1.2}
              fill='none'
              width={30}
              height={30}
            ></rect>
            {/* Tacca */}
            <line x1={x * 0.9} y1={y * 0.9} x2={x} y2={y} stroke="black" />
            {/* Etichetta */}
            <text x={x * 1.1} y={y * 1.1} fontSize="10" textAnchor="middle">
              {angle.label}
            </text>
          </g>
        );
      })}

      {/* Disegno del Raggio e dei Segmenti Tratteggiati */}
      {selectedAngle && (
        <>
          {/* Raggio dal centro al punto selezionato */}
          <line
            x1="0"
            y1="0"
            x2={radius * Math.cos(selectedAngle.radians)}
            y2={-radius * Math.sin(selectedAngle.radians)}
            stroke="blue"
          />

          {/* Segmento orizzontale tratteggiato fino all'asse Y */}
          <line
            x1={radius * Math.cos(selectedAngle.radians)}
            y1={-radius * Math.sin(selectedAngle.radians)}
            x2="0"
            y2={-radius * Math.sin(selectedAngle.radians)}
            stroke="green"
            strokeDasharray="5,5"
          />
          {/* Segmento verticale sull'asse Y */}
          <line
            x1="0"
            y1="0"
            x2="0"
            y2={-radius * Math.sin(selectedAngle.radians)}
            stroke="green"
          />

          {/* Segmento verticale tratteggiato fino all'asse X */}
          {
            selectedAngle.radians !== (3 * Math.PI) / 2 && selectedAngle.radians !== Math.PI / 2 &&
            <line
            x1={radius * Math.cos(selectedAngle.radians)}
            y1={-radius * Math.sin(selectedAngle.radians)}
            x2={radius * Math.cos(selectedAngle.radians)}
            y2="0"
            stroke="purple"
            strokeDasharray="5,5"
          />
          }
          

               {/* Segmento orizzontale sull'asse X */}
          <line
            x1="0"
            y1="0"
            x2={radius * Math.cos(selectedAngle.radians)}
            y2="0"
            stroke="purple"
          />

          {/* Valori di Seno e Coseno sui punti di intersezione */}
          <text
            x={radius * Math.cos(selectedAngle.radians) + (radius * Math.cos(selectedAngle.radians) > 0 ? -20 : 20)}
            y={radius * Math.sin(selectedAngle.radians) > 0 ? "15" : "-15"}
            fontSize="10"
            fill="purple"
            textAnchor="middle"
          >
            cos: {formatTrigValue(Math.cos(selectedAngle.radians))}
          </text>
          <text
            x="5"
            y={-radius * Math.sin(selectedAngle.radians) + (-radius * Math.sin(selectedAngle.radians) > 0 ? -10 : 10)}
            fontSize="10"
            fill="green"
            textAnchor="start"
          >
            sin: {formatTrigValue(Math.sin(selectedAngle.radians))}
          </text>

          {/* Punto sulla circonferenza */}
          <circle
            cx={radius * Math.cos(selectedAngle.radians)}
            cy={-radius * Math.sin(selectedAngle.radians)}
            r="3"
            fill="red"
          />
        </>
      )}
    </svg>
  );
};

export default UnitCircle;