import React from 'react';

const Articoli = () => {
  return (
    <div className='content'>
      <h1>Pagina Articoli</h1>
      <p>Questa è la pagina dedicata agli articoli.</p>
    </div>
  );
};

export default Articoli;