import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Navbar.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons'
import DarkModeToggle from './DarkModeToggle';


const Navbar = () => {

  const navigate = useNavigate();

  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const [showDidatticaDropdown, setShowDidatticaDropdown] = useState(false);
  const [showMobileDidatticaDropdown, setShowMobileDidatticaDropdown] = useState(false);

  
const hideMenu = () => {
  setShowMobileDidatticaDropdown(false);
  setShowMobileMenu(false);
}

  return (
    <div className='navbar-container'>
      <nav className="navbar align-items-center">
        <FontAwesomeIcon icon={faBars} size='2x' className='menu-icon' onClick={()=>{setShowMobileMenu(true)}}/>
        {/* Logo a sinistra */}
        <div className="logo-container" onClick={()=>{navigate('/'); setShowDidatticaDropdown(false)}}>
          <div className="logo-img">
              <img src="/logo.png" alt="FisicHub Logo" />
          </div>
          <h1 >FisicHub</h1>
        </div>
        
        {/* Link centrali */}
        <ul className="nav-links">
        <li onClick={()=>{navigate('/'); setShowMobileMenu(false); setShowDidatticaDropdown(false)}}>
            Home
          </li>
          <li onClick={()=>{setShowDidatticaDropdown(!showDidatticaDropdown)}}>
            Didattica
            {showDidatticaDropdown && (
                <ul className="didattica-dropdown-menu">
                  <li onClick={()=>{navigate('/didattica')}}>Lezioni</li>
                  <li onClick={()=>{navigate('/simulazioni')}}>Simulazioni</li>
                  <li onClick={()=>{navigate('/questionari')}}>Questionari</li>
                </ul>
              )}
          </li>
          <li onClick={()=>{navigate('/articoli'); setShowDidatticaDropdown(false)}}>
            Articoli
          </li>
          <li onClick={()=>{navigate('/contatti'); setShowDidatticaDropdown(false)}}>
            Contatti
          </li>
        </ul>
        <div className='desktop-dark-toggle'>
          <DarkModeToggle/>
        </div>
        

        
      </nav>
      <div className={`mobile-menu ${showMobileMenu ? 'mobile-menu-translate' : ''}`}>
        <FontAwesomeIcon icon={faXmark} size="2x" onClick={()=>{setShowMobileMenu(false)}} className='mobile-menu-close-icon'/>
          <ul className='mobile-nav-links flex-column'>
          <li onClick={()=>{navigate('/'); hideMenu()}}>
            Home
          </li>
          <li onClick={()=>{setShowMobileDidatticaDropdown(!showMobileDidatticaDropdown)}}>
            Didattica
          </li>
          {showMobileDidatticaDropdown && (
                <ul className="mobile-nav-sub-links flex-column margin-0">
                  <li onClick={()=>{navigate('/didattica');hideMenu()}}>Lezioni</li>
                  <li onClick={()=>{navigate('/simulazioni');hideMenu()}}>Simulazioni</li>
                  <li onClick={()=>{navigate('/questionari');hideMenu()}}>Questionari</li>
                </ul>
              )}
          <li onClick={()=>{navigate('/articoli');hideMenu()}}>
            Articoli
          </li>
          <li onClick={()=>{navigate('/contatti'); hideMenu()}}>
            Contatti
          </li>
          </ul>
          <div className='mobile-dark-toggle'>
            <DarkModeToggle/>
          </div>
      </div>
      
    </div>
  );
};

export default Navbar;