import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './Lezione.css'
import { getPostByIDAndFields } from '../services/PostService';

const Lezione = () => {
  const [lezione, setLezione] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { id } = useParams();

  const url= `https://public-api.wordpress.com/rest/v1.1/sites/${process.env.REACT_APP_SITE_ID}/posts/${id}`

  const fetchLezione = async () => {
    let queryParams = {
      'fields': 'ID, title, content, post_thumbnail, terms'
    };

    try {
      const response = await getPostByIDAndFields(id, queryParams)
      setLezione(response.data);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLezione();
  }, [id]);

  if (loading) return <p>Caricamento...</p>;
  if (error) return <p>Si è verificato un errore: {error.message}</p>;

  return (
    <>
      <div className="lezione flex-column align-items-center">
        <h1>{lezione?.title}</h1>
        <div className='lezione-content' dangerouslySetInnerHTML={{ __html: lezione?.content }} />
      </div>
      <div>
        {lezione?.terms?.post_tag?.riduzione?.meta?.links.self}
      </div>
    </>
  );
};

export default Lezione;