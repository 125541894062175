import React from "react";


const HarmonicWave = () =>{
    return (
        <div>
            <iframe src="https://www.desmos.com/calculator/68kqtrsilt" width="90%" height="500" frameborder="0"></iframe>
        </div>
    )
}

export default HarmonicWave;